import styled, { keyframes, css } from 'styled-components';

export const placeload = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const animation = css`
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeload};
  animation-timing-function: linear;
  background: linear-gradient(to right, #f6f4f1 8%, #eae5e1 18%, #f6f4f1 33%);
  background-size: 1200px 104px;
`;

const placeholderRow = css`
  height: 15px;
  background-color: ${({ theme: { colors } }) => colors.background.secondary};
  border-radius: 4px;
  margin: 5px 0;
`;

export const PlaceholderCard = styled.div`
  width: 48.5%;
  margin-right: 2.9%;

  &:nth-of-type(2n + 2) {
    margin: 0;
  }

  &:nth-of-type(-n + 4) {
    margin-top: 10px;
  }
`;

export const PlaceholderCardImg = styled.div`
  height: 130px;
  background-color: ${({ theme: { colors } }) => colors.background.secondary};
  border-radius: 14px;
  ${animation};

  @media (min-width: ${({ theme }) =>
      theme.tabletBreakpoint}) and (max-width: ${({ theme }) =>
      theme.smallDesktopBreakpoint}) {
    height: 150px;
  }

  @media (min-width: ${({ theme }) =>
      theme.smallDesktopBreakpoint}) and (max-width: ${({ theme }) =>
      theme.desktopBreakpoint}) {
    height: 204px;
  }

  @media (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    height: 173px;
  }
`;

export const PlaceholderCardPrice = styled.div`
  ${placeholderRow};
  ${animation};
  width: 71%;
  margin-top: 7.5px;
  @media (min-width: ${({ theme }) =>
      theme.tabletBreakpoint}) and (max-width: ${({ theme }) =>
      theme.smallDesktopBreakpoint}) {
    margin-top: 10.5px;
  }

  @media (min-width: ${({ theme }) =>
      theme.smallDesktopBreakpoint}) and (max-width: ${({ theme }) =>
      theme.desktopBreakpoint}) {
    margin-top: 5px;
  }

  @media (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    margin-top: 10px;
  }
`;

export const PlaceholderCardLocation = styled.div`
  ${placeholderRow};
  ${animation};
  width: 71%;
`;

export const PlaceholderCardDescription = styled.div`
  ${placeholderRow};
  ${animation};
  width: 60%;
`;

export const PlaceholderCardDate = styled.div`
  ${placeholderRow};
  ${animation};
  width: 50%;
`;
