import { useForm, useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { BUILDING_TYPE_ID } from '../utils/helpers/constants';
import { setCategoryIds } from '../actions';

export function useBuildingType() {
  const { change, submit } = useForm();
  const dispatch = useDispatch();
  const router = useRouter();
  const { values } = useFormState();

  const categoryIds = useSelector(state => state.searchForm.categoryIds);
  const updateCategoryIds = (initialResult = [], id) => {
    let result = initialResult.filter(item => item !== undefined).slice();
    if (result.includes(id)) {
      result = result.filter(prevId => prevId !== id);
    } else {
      result.push(id);
    }
    result = result.filter(item => item !== '1');
    const isSelectedAll = !result.length || result.length > 1;
    change(
      'category_id',
      isSelectedAll ? BUILDING_TYPE_ID.FLAT_ALL : result[0]
    );
    return result;
  };

  const handleBuildingType = id => {
    const result = updateCategoryIds(categoryIds, id);

    submit();
    dispatch(setCategoryIds(result));
  };

  useEffect(() => {
    const popStateHandler = popState => {
      if (
        popState.state?.as !== '/' &&
        popState?.state?.url.includes('[...search]')
      ) {
        submit();
      }
    };

    window.addEventListener('popstate', popStateHandler);

    return () => window.removeEventListener('popstate', popStateHandler);
  }, [router.asPath]);

  useEffect(() => {
    if (values.category_id !== BUILDING_TYPE_ID.FLAT_ALL) {
      dispatch(setCategoryIds([values.category_id]));
    }
  }, []);

  return { handleBuildingType, updateCategoryIds, categoryIds };
}
