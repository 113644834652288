import React from 'react';
import {
  PlaceholderCard,
  PlaceholderCardDate,
  PlaceholderCardDescription,
  PlaceholderCardImg,
  PlaceholderCardLocation,
  PlaceholderCardPrice
} from './styled';

export const ItemCardPlaceholder = () => {
  return (
    <PlaceholderCard>
      <PlaceholderCardImg />
      <PlaceholderCardPrice />
      <PlaceholderCardLocation />
      <PlaceholderCardDescription />
      <PlaceholderCardDate />
    </PlaceholderCard>
  );
};
