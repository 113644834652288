import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const NewRadioButton = ({
  data,
  name,
  color,
  stat,
  cypress,
  currentValue,
  onChangeHandler,
  containerClassNames,
  additionalClasses
}) => {
  const classes = cn(
    'new-search-radio-container',
    containerClassNames,
    additionalClasses
  );

  return (
    <label className={classes} data-cy="radio-container" data-stat={stat}>
      <input
        type="radio"
        name={name}
        value={data.id}
        onChange={onChangeHandler}
        data-cy={`${cypress}-${data.id}`}
        checked={currentValue === data.id}
        className="new-search-radio input-hidden"
      />
      <div
        data-cy={cypress}
        className="new-search-radio__text"
        style={{ color: currentValue === data.id ? color : '#2f1f19' }}
      >
        {data.name}
      </div>
      <span className="new-search-radio__mark-container">
        <span className="new-search-radio__mark" />
      </span>
    </label>
  );
};

NewRadioButton.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  color: PropTypes.string,
  cypress: PropTypes.string,
  stat: PropTypes.string,
  currentValue: PropTypes.string,
  onChangeHandler: PropTypes.func,
  additionalClasses: PropTypes.string,
  containerClassNames: PropTypes.string
};

export default NewRadioButton;
