export const selectSearchFormState = state => state.searchForm;
export const selectBlockerDetectorState = state => state.blockerDetector;
export const selectErrorUIState = state => state.errorUI;
export const selectSideMenuModalState = state => state.sideMenuModal;
export const selectPromotionModalViewState = state => state.promotionModalView;
export const selectRestoreAlertModalState = state => state.restoreAlertModal;
export const selectCalcFormState = state => state.calcForm;
export const selectSuccessPromotionModalViewState = state =>
  state.successPromotionModalView;
export const selectFailurePaymentModalViewState = state =>
  state.failurePaymentModalView;
export const selectItemsBalanceModalViewState = state =>
  state.itemsBalanceModalView;
export const selectBuyItemsModalViewState = state => state.buyItemsModalView;
export const selectWalletPayModalViewState = state => state.walletPayModalView;
export const selectTerminalModalViewState = state => state.terminalModalView;
export const selectPersonalAccountModalViewState = state =>
  state.personalAccountModalView;
export const selectProfilePromotionItemId = state =>
  state.profilePromotionItemId;
export const selectProfileEditSuccessMessage = state =>
  state.profileEditSuccessMessage;
export const selectProfilePromotionItemsModal = state =>
  state.profilePromotionItemsModalView;
export const selectPackagePayModalViewState = state =>
  state.packagePayModalView;
export const selectSuccessWebviewPaymentStatusModalViewState = state =>
  state.successWebViewPaymentStatusModalView;
export const selectFailureWebViewPaymentStatusModalViewState = state =>
  state.failureWebViewPaymentStatusModalView;
export const selectSearchLocationNames = state =>
  state.searchForm.chosenLocationNames;
