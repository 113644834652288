import { useQuery } from '@apollo/client';
import { bookmarksAllQuery } from '../queries';

export const useGetBookmarks = () => {
  const bookmarksVariable = {
    limit: 100
  };
  const { data } = useQuery(bookmarksAllQuery, {
    variables: bookmarksVariable,
    ssr: false,
    fetchPolicy: 'network-only'
  });

  const isBookmarked = id => data?.bookmarks?.some(el => el?.item?.id === id);

  return {
    isBookmarked,
    bookmarkData: data?.bookmarks
  };
};
