import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';
import {
  setChosenLocationNames,
  setCurrentCity,
  setLocationIds
} from '../actions';

export function useUndoFilters() {
  const dispatch = useDispatch();
  const { values } = useFormState();
  const { change } = useForm();
  const currentCity = useSelector(state => state.searchForm.currentCity);
  const locationIds = useSelector(state => state.searchForm.locationIds);
  const chosenLocationNames = useSelector(
    state => state.searchForm.chosenLocationNames
  );

  return function undoFilters() {
    Object.keys(values).forEach(key => {
      change(key, values[key]);
    });
    dispatch(setChosenLocationNames(chosenLocationNames));
    dispatch(setLocationIds(locationIds));
    dispatch(setCurrentCity(currentCity));
  };
}
