import PasswordIcon from '../../../public/static/images/new_search/icons/icon-password.svg';
import CalendarIcon from '../../../public/static/images/new_search/icons/icon-calendar.svg';
import MoonIcon from '../../../public/static/images/new_search/icons/icon-moon.svg';
import { setCurrentState } from '../../../actions';
import { onClickProps, Value } from './types';

export const mobileTabsConfig = [
  {
    id: 'buy-link',
    href: '/alqi-satqi',
    cypress: 'tab-link',
    icon: PasswordIcon,
    text: 'new_search.sell',
    stat: 'new-search-buy-button',
    onClick: ({ change, dispatch, values }: onClickProps) => {
      change('category_id', undefined);
      change('paid_daily', undefined);
      dispatch(
        setCurrentState({
          ...values,
          city_id: '0',
          leased: 'false',
          category_id: undefined
        })
      );
    }
  },
  {
    id: 'rent-link',
    href: '/kiraye',
    cypress: 'tab-link',
    icon: CalendarIcon,
    text: 'new_search.rent',
    stat: 'new-search-rent-button',
    onClick: ({ change, dispatch, values }: onClickProps) => {
      change('category_id', undefined);
      change('leased', 'true');
      dispatch(
        setCurrentState({
          ...values,
          city_id: '0',
          leased: 'true',
          category_id: undefined
        })
      );
    }
  },
  {
    id: 'paid_daily-link',
    href: {
      pathname: '/kiraye',
      query: { paid_daily: true }
    },
    icon: MoonIcon,
    cypress: 'tab-link',
    text: 'search.form.paid_daily',
    stat: 'new-search-paid-daily-button',
    onClick: ({ change, dispatch, values }: onClickProps) => {
      change('category_id', undefined);
      change('leased', 'true');
      change('paid_daily', ['true']);
      dispatch(
        setCurrentState({
          ...values,
          city_id: '0',
          leased: 'true',
          category_id: undefined,
          paid_daily: ['true']
        })
      );
    }
  }
];

export const tabsConfig = [
  {
    id: 'buy-link',
    cypress: 'tab-link',
    text: 'new_search.purchase',
    stat: 'new-search-buy-button',
    getIsSelected: (value: Value) => value === 'false',
    onClick: ({ change, dispatch, values }: onClickProps) => {
      change('leased', 'false');
      change('category_id', undefined);
      change('paid_daily', undefined);
      dispatch(
        setCurrentState({
          ...values,
          city_id: '0',
          leased: 'false',
          category_id: undefined
        })
      );
    }
  },
  {
    id: 'rent-link',
    cypress: 'tab-link',
    text: 'new_search.lease',
    stat: 'new-search-rent-button',
    getIsSelected: (value: Value) => value === 'true',
    onClick: ({ change, dispatch, values }: onClickProps) => {
      change('category_id', undefined);
      change('leased', 'true');
      dispatch(
        setCurrentState({
          ...values,
          city_id: '0',
          leased: 'true',
          category_id: undefined
        })
      );
    }
  }
];
