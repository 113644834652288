import styled, { css } from 'styled-components';
import {
  ellipsisText,
  FlexBetween,
  FlexCenterAligned,
  FlexibleDiv
} from '../../../styled/common';

const iconSize = '18px';
const padding = '15px';

export const filterButtonStyles = css`
  width: 48px;
  height: 48px;
  padding: 10px;
`;

export const findButtonStyles = css`
  width: 77px;
  height: 48px;
  padding: 10px 15px;
`;

export const SearchBarContainer = styled.div<{ extraPadding: boolean }>`
  padding: ${({ extraPadding }) => (extraPadding ? '65px 15px 0' : '0 15px 0')};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;

  @media screen and (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    padding-right: 40px;
    padding-left: 40px;
    gap: 5px;
  }

  @media screen and (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    padding-right: 20px;
    padding-left: 20px;
  }
`;

export const SearchBarWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: 14px;
  overflow: hidden;
  position: relative;
  width: calc(100% - 48px - 10px);
  height: 48px;
`;

export const SearchBar = styled(FlexBetween)`
  position: relative;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.colors.background.tertiary};
  padding-left: calc(${iconSize} + ${padding} + 10px);
  padding-right: 35px;
  height: 48px;

  &::-webkit-scrollbar {
    display: none;
  }

  &::before {
    content: '';
    background-image: url('/static/images/new_search/icons/icon-pin.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: ${padding};
    top: 50%;
    transform: translateY(-50%);
    width: ${iconSize};
    height: ${iconSize};
  }

  input[type='text'] {
    border-style: none;
    width: 100%;
    color: ${({ theme }) => theme.colors.text.secondary};
    padding: ${iconSize} 0;
    background: transparent;
    ${ellipsisText}

    &:focus {
      outline: none;
    }
  }
`;

export const SearchBarText = styled.div`
  width: 100%;
`;

export const SearchBarLocations = styled.div`
  ${ellipsisText};
  color: ${({ theme }) => theme.colors.text.primary};
  width: 100%;
  max-width: 100%;
`;

export const SearchBarPlaceholder = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const ResetButton = styled(FlexCenterAligned)`
  position: absolute;
  padding: 0 15px 0 10px;
  width: 40px;
  background-color: ${({ theme }) => theme.colors.background.tertiary};
  right: 0;
  top: 0;
  height: 100%;
`;

export const FiltersFloatButton = styled(FlexibleDiv)`
  position: fixed;
  right: 5px;
  bottom: 95px;
  z-index: 2;
  color: ${({ theme }) => theme.colors.background.primary};
  font-size: 15px;
  height: 38px;
  padding: 10px 15px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.systemColors.blue};
  opacity: 0;
  transition: opacity 0.4s ease;
`;
