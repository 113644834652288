import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import NewChips from '../common/NewChips';
import { RentalTabsContainer, TabLink } from './styled';
import { RentalTabsProps } from './types';
import { mobileTabsConfig, tabsConfig } from './tabsConfig';
import useScreenSize from '../../../hooks/useScreenSize';
import { Tab } from '../../ui/Tab';

export const RentalTabs = ({ change, values }: RentalTabsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isSmall } = useScreenSize();

  return (
    <RentalTabsContainer data-cy="new-search__chips-wrapper">
      {isSmall
        ? mobileTabsConfig.map(tab => (
            <TabLink key={tab.id} id={tab.id} href={tab.href}>
              <NewChips
                type="button"
                icon={tab.icon}
                cypress={tab.cypress}
                borderRadius="14px"
                text={t(tab.text)}
                stat={tab.stat}
                onClick={() => tab.onClick({ change, values, dispatch })}
                isTextPadding={false}
              />
            </TabLink>
          ))
        : tabsConfig.map(tab => (
            <Tab
              key={tab.id}
              id={tab.id}
              cypress={tab.cypress}
              text={t(tab.text)}
              onClick={() => tab.onClick({ change, values, dispatch })}
              isSelected={tab.getIsSelected(values.leased)}
            />
          ))}
    </RentalTabsContainer>
  );
};
