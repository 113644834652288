import { useEffect, useRef, useState } from 'react';
import { debounce } from '../utils/debounce';

export const useFixAndroidPasswordManagerBarBug = isOpen => {
  const [isFocused, setIsFocused] = useState(false);
  const [heightDiff, setHeightDiff] = useState(null);
  const initialHeightRef = useRef(window.visualViewport?.height);
  const hasDebouncedOnce = useRef(false);
  const maxPasswordManagerBarHeight = 100;
  const minKeyboardHeight = 300;
  const debounceTimeout = 500;

  useEffect(() => {
    const isAndroid = /Android/.test(navigator.userAgent) && !window.MSStream;
    const handleResize = () => {
      const newHeight = window.visualViewport?.height;
      const heightDifference = newHeight - initialHeightRef.current;
      const heightDiffAbsoluteValue = Math.abs(heightDifference);

      if (heightDiffAbsoluteValue > minKeyboardHeight) {
        if (heightDifference < 0) {
          initialHeightRef.current = newHeight;
        } else {
          hasDebouncedOnce.current = false;
        }
        setHeightDiff(null);
      }

      if (heightDiffAbsoluteValue < maxPasswordManagerBarHeight) {
        setHeightDiff(heightDiffAbsoluteValue);
      }
    };
    const debouncedHandleResize = debounce(() => {
      handleResize();
      hasDebouncedOnce.current = true;
    }, debounceTimeout);

    const resizeHandler = () => {
      if (isOpen && isAndroid) {
        if (!hasDebouncedOnce.current) {
          debouncedHandleResize();
        } else {
          handleResize();
        }
      }
    };

    setHeightDiff(null);
    initialHeightRef.current = window.visualViewport?.height;

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [isOpen, isFocused]);

  return {
    setIsFocused,
    heightDiff
  };
};
