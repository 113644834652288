import React, { useEffect, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import Icon from '../../Icon';
import FilterIcon from '../../../public/static/images/new_search/icons/icon-filter.svg';
import FilterSmallIcon from '../../../public/static/images/new_search/icons/icon-filter-small.svg';
import DeleteIcon from '../../../public/static/images/new_search/icons/icon-delete.svg';
import {
  setChosenLocationNames,
  setCurrentCity,
  setError,
  setLocationIds
} from '../../../actions';
import { cityQuery } from '../../../queries';
import { Button } from '../../ui/Button';
import {
  filterButtonStyles,
  FiltersFloatButton,
  findButtonStyles,
  ResetButton,
  SearchBar,
  SearchBarContainer,
  SearchBarLocations,
  SearchBarPlaceholder,
  SearchBarText,
  SearchBarWrapper
} from './styled';
import { useUndoFilters } from '../../../hooks/useUndoFilters';
import useScreenSize from '../../../hooks/useScreenSize';
import { ModalParams, NewSearchBarProps } from './types';
import { selectSearchLocationNames } from '../../../store/selectors';

const NewSearchFilters = dynamic(() => import('../NewSearchFilters'), {
  ssr: false
});

export const NewSearchBar = ({
  formEl,
  extraPadding,
  searchVariables,
  initialFormValues
}: NewSearchBarProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { values } = useFormState();
  const { change, submit } = useForm();
  const undoFilters = useUndoFilters();
  const { isSmall } = useScreenSize();
  const cityId = values.city_id || '0';
  const chosenLocationNames = useSelector(selectSearchLocationNames);
  const [modalParams, setModalParams] = useState<ModalParams>(null);
  const hasChosenLocationsOrCity =
    chosenLocationNames?.length > 0 ||
    (cityId && cityId !== '1' && cityId !== '0');

  const { data, error, loading } = useQuery(cityQuery(cityId), {
    variables: {
      id: cityId
    }
  });

  const cityName =
    data && data.city !== null ? data.city.name : t('search.city_search.any');

  useEffect(() => {
    if (error) dispatch(setError(true));
  }, [error]);

  useEffect(() => {
    const scrollHandler = () => {
      const scrollButton = document.getElementById('search_float_button');
      if (!scrollButton) return;
      if (window.pageYOffset > 300) {
        scrollButton.style.opacity = '1';
        scrollButton.style.visibility = 'visible';
      } else {
        scrollButton.style.opacity = '0';
        scrollButton.style.visibility = 'hidden';
      }
    };
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const toggleHandlerLocations = () => {
    const hasLocationOrCitySelected =
      chosenLocationNames?.length > 0 || (cityId && cityId !== '0');
    if (hasLocationOrCitySelected) {
      setModalParams({
        initialScreen: 'locations'
      });
    } else {
      setModalParams({
        initialScreen: 'city'
      });
    }
  };

  const resetChosenLocations = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (values.city_id !== '0') {
      dispatch(setCurrentCity('0'));
      change('city_id', undefined);
    }
    change('location_ids', undefined);
    dispatch(setLocationIds([]));
    dispatch(setChosenLocationNames([]));
    submit();
  };

  const openFilters = () =>
    setModalParams({
      initialScreen: 'filters'
    });

  const hideFilters = () => setModalParams(null);

  const handleHideWithUndo = () => {
    undoFilters();
    hideFilters();
  };

  return (
    <SearchBarContainer
      extraPadding={extraPadding}
      data-cy="search-bar-container"
    >
      <SearchBarWrapper>
        <SearchBar
          tabIndex={0}
          role="button"
          onClick={toggleHandlerLocations}
          onKeyPress={toggleHandlerLocations}
          data-stat="new-search-search-bar-locations"
        >
          <Field name="location_ids" component="input" type="hidden" />
          <SearchBarText>
            {((cityId !== '1' && cityId !== '0') ||
              chosenLocationNames.length) &&
            !loading ? (
              <SearchBarLocations data-cy="chosen-locations-names">
                {[cityName, ...chosenLocationNames].join(', ')}
              </SearchBarLocations>
            ) : (
              <SearchBarPlaceholder data-cy="chosen-locations-names-title">
                {t('search.locations_search.locations_placeholder.all_groups')}
              </SearchBarPlaceholder>
            )}
          </SearchBarText>
        </SearchBar>

        {hasChosenLocationsOrCity && (
          <ResetButton
            tabIndex={0}
            role="button"
            onClick={resetChosenLocations}
            onKeyPress={resetChosenLocations}
            data-cy="search-bar-reset"
          >
            <Icon
              width={15}
              height={15}
              indent={false}
              IconComponent={DeleteIcon}
            />
          </ResetButton>
        )}
      </SearchBarWrapper>

      <Button
        onClick={openFilters}
        onKeyPress={openFilters}
        cypress="new-search-filters-button"
        stat="new-search-filters-button"
        styles={filterButtonStyles}
      >
        <Icon
          width={20}
          height={20}
          indent={false}
          IconComponent={FilterIcon}
        />
      </Button>

      {!isSmall && (
        <Button
          type="submit"
          cypress="new-search-find-button"
          stat="new-search-find-button"
          styles={findButtonStyles}
          text={t('search.buttons.find')}
          onClick={() => {}}
        />
      )}

      <FiltersFloatButton
        tabIndex={0}
        role="button"
        onClick={openFilters}
        onKeyPress={openFilters}
        id="search_float_button"
        data-stat="new-search-filters-float-button"
      >
        <Icon width={18} height={18} IconComponent={FilterSmallIcon} />
        {t('search.buttons.filters')}
      </FiltersFloatButton>

      <NewSearchFilters
        formEl={formEl}
        hide={hideFilters}
        isOpen={!!modalParams}
        undo={handleHideWithUndo}
        modalParams={modalParams}
        searchVariables={searchVariables}
        initialFormValues={initialFormValues}
      />
    </SearchBarContainer>
  );
};
